import './HeaderMenuMobile.scss';

import React, {
  // useEffect,
  // useRef,
  useState,
} from 'react';
import {
  generatePath,
  RouteComponentProps,
  // useHistory,
} from 'react-router-dom';

import { PropsWithJsxAttributes } from '../../../utils/types/PropsWithJsxAttributes';
import { cn } from '../../../utils/bem';
// import { useChoiceGroup } from '@dataartdev/uikit/useChoiceGroup';
import { IconArrowDown } from '@dataartdev/uikit/IconArrowDown';
import { IconOpenInNew } from '@dataartdev/uikit/IconOpenInNew';
import { Button } from '@dataartdev/uikit/Button';
import { Typography } from '@dataartdev/uikit/Typography';
import { IconArrowRight } from '@dataartdev/uikit/IconArrowRight';
import { Picture } from '@dataartdev/uikit/Picture';
// import TagManager from 'react-gtm-module';
import {
  Language,
  Menu,
  // Column
} from '../../../../store/models';

export const cnHeaderMenuMobile = cn('HeaderMenuMobile');

type MenuProps = Pick<RouteComponentProps, 'match'> &
  PropsWithJsxAttributes<
    {
      items: Menu[];
      language?: Language;
      languages?: Language[];
    },
    'nav'
  >;

export const HeaderMenuMobile: React.FC<MenuProps> = ({
  items,
  className,
  onClick,
  match,
  language,
  languages = [],
}) => {
  const setParams = ({
    title,
    nodeUrl,
    externalLink,
    targetBlank,
  }: {
    title?: string;
    nodeUrl?: string;
    externalLink?: string;
    targetBlank: boolean;
  }) => {
    return !!nodeUrl
      ? ({
          onClick,
          width: 'full',
          isLink: !!nodeUrl,
          as: !!nodeUrl ? 'link' : 'a',
          to: nodeUrl ?? '/404',
          target: targetBlank ? '_blank' : '_self',
          label: title ?? '',
          exact: true,
        } as const)
      : ({
          onClick,
          width: 'full',
          as: !!nodeUrl ? 'link' : 'a',
          href: externalLink,
          target: targetBlank ? '_blank' : '_self',
          label: title ?? '',
        } as const);
  };

  // const [openedKeys, setOpenedKeys] = useState<(Menu | Column)[] | null>(null);

  // const { getOnChange, getChecked } = useChoiceGroup({
  //   value: openedKeys,
  //   getKey: item => {
  //     return (item as Menu).node?.title || (item as Column)?.title;
  //   },
  //   callBack: (params: {
  //     e: React.MouseEvent<HTMLButtonElement>;
  //     value: (Menu | Column)[] | null;
  //   }) => {
  //     setOpenedKeys(params.value);
  //   },
  //   multiple: true,
  // });

  const mainMenuItems =
    items?.filter(item => !item.node?.nodeUrl?.includes('/contact-us')) ?? [];

  const contactUsItem = items?.find(item =>
    item.node?.nodeUrl?.includes('/contact-us')
  );

  // const history = useHistory();
  // const servicesHandler = async (title: string, nodeUrl?: string) => {
  //   if (!nodeUrl?.includes('services')) return;
  //   const service = title.trim().toLowerCase();
  //   let dataLayer = {
  //     dataLayer: {
  //       event: 'click_services',
  //       services:
  //         service === 'services'
  //           ? 'click_all_services'
  //           : `click_${service.split(' ').join('_')}`,
  //     },
  //   };
  //   try {
  //     TagManager.dataLayer(dataLayer);
  //
  //     if (nodeUrl.includes('http')) {
  //       window.location.href = nodeUrl;
  //     } else {
  //       history.push(nodeUrl);
  //     }
  //   } catch (error) {
  //     error instanceof Error
  //       ? console.error(error.message)
  //       : console.error('Error with TagManager dataLayer:', error);
  //   }
  // };

  const [openMenu, setOpenMenu] = useState<string | null>(null);

  // const [fixedMenu, setFixedMenu] = useState<string | null>(null);
  // const menuRefs = useRef(new Map());
  // const containerRef = useRef<HTMLDivElement>(null);

  const toggleMenu = (title: string) => {
    setOpenMenu(prev => (prev === title ? null : title));
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     menuRefs.current.forEach((element, title) => {
  //       if (element) {
  //         const rect = element.getBoundingClientRect();
  //         const isOpen = openMenu === title;
  //         const hasChildren = mainMenuItems.some(
  //           item =>
  //             item.node.title === title &&
  //             ((item.nodes && item.nodes?.length > 0) ||
  //               (item.columns && item.columns?.length > 0))
  //         );
  //         if (rect.top <= 64 && isOpen && hasChildren) {
  //           setFixedMenu(title);
  //         } else if (fixedMenu === title && rect.top > 64) {
  //           setFixedMenu(null);
  //         }
  //       }
  //     });
  //   };
  //
  //   const scrollTarget = containerRef.current || window;
  //   scrollTarget.addEventListener('scroll', handleScroll);
  //
  //   return () => {
  //     scrollTarget.removeEventListener('scroll', handleScroll);
  //   };
  // }, [fixedMenu, openMenu]);

  return (
    <nav className={cnHeaderMenuMobile(null, [className])}>
      <div
        // ref={containerRef}
        className={cnHeaderMenuMobile('Main')}
      >
        <div className={cnHeaderMenuMobile('List')}>
          {mainMenuItems.map(item => {
            if (item.type === 1) {
              const {
                node,
                columns = [],
                sideColumn,
                // nodes,
                // additionalNodes
              } = item || {};
              const { title } = node || {};
              const hasChildren = !!(
                columns.length > 0 ||
                (sideColumn && sideColumn?.nodes?.length > 0)
              );
              return (
                <div
                  className={cnHeaderMenuMobile('Menu-Item')}
                  key={item.node.title}
                  // ref={el => {
                  //   if (el) menuRefs.current.set(title, el);
                  // }}
                >
                  <Button
                    className={cnHeaderMenuMobile('Link', {
                      primary: true,
                      active: openMenu === title,
                      hasChildren: columns.length > 0,
                      // open: fixedMenu === title,
                    })}
                    view="transparent"
                    align="left"
                    iconRight={IconArrowDown}
                    label={title}
                    onClick={() => toggleMenu(title)}
                  />
                  <div
                    className={cnHeaderMenuMobile('Sub-List', {
                      visible: openMenu === title,
                    })}
                  >
                    {hasChildren &&
                      [...columns, sideColumn].map(column => {
                        const { nodes = [], bottomNode } = column || {};
                        return (
                          <React.Fragment key={node.nodeUrl}>
                            {nodes.map(node => {
                              return (
                                <Button
                                  key={node.title}
                                  className={cnHeaderMenuMobile('Link', {
                                    secondary: node.style.name === 'Bold',
                                    tertiary: node.style.name === 'None',
                                    all: node.style.name === 'Thin',
                                    withLogo: !!node.logo,
                                  })}
                                  view="transparent"
                                  align="left"
                                  iconRight={
                                    !!node.logo ? IconOpenInNew : undefined
                                  }
                                  iconLeft={() =>
                                    !!node.logo ? (
                                      <Picture {...node.logo} />
                                    ) : null
                                  }
                                  {...setParams({
                                    title: node.title,
                                    nodeUrl: node.nodeUrl,
                                    externalLink: node.externalLink,
                                    targetBlank: node.targetBlank,
                                  })}
                                />
                              );
                            })}
                            {bottomNode && bottomNode.logo && (
                              <Button
                                className={cnHeaderMenuMobile('Link', {
                                  secondary: bottomNode.style.name === 'Bold',
                                  isImage: !!bottomNode.logo,
                                })}
                                view="transparent"
                                align="left"
                                as="a"
                                href={bottomNode.externalLink}
                              >
                                <Picture {...bottomNode.logo} />
                              </Button>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </div>
                </div>
              );
            }
            if (item.type === 0) {
              const { nodes = [], node } = item || {};
              return (
                <div
                  className={cnHeaderMenuMobile('Menu-Item')}
                  // ref={el => {
                  //   if (el) menuRefs.current.set(node.title, el);
                  // }}
                >
                  <Button
                    className={cnHeaderMenuMobile('Link', {
                      primary: node.style.name === 'Bold',
                      active: openMenu === node.title,
                      hasChildren: nodes.length > 0,
                      // open: fixedMenu === node.title,
                    })}
                    view="transparent"
                    align="left"
                    iconRight={nodes.length > 0 ? IconArrowDown : undefined}
                    label={node.title}
                    onClick={() => toggleMenu(node.title)}
                  />
                  <div
                    className={cnHeaderMenuMobile('Sub-List', {
                      visible: openMenu === node.title,
                    })}
                  >
                    {nodes.length > 0 &&
                      nodes.map(node => {
                        return (
                          <Button
                            className={cnHeaderMenuMobile('Link', {
                              secondary: node.style.name === 'None',
                            })}
                            view="transparent"
                            align="left"
                            {...setParams({
                              title: node.title,
                              nodeUrl: node.nodeUrl,
                              externalLink: node.externalLink,
                              targetBlank: node.targetBlank,
                            })}
                          />
                        );
                      })}
                  </div>
                </div>
              );
            }
          })}
        </div>

        {/*OLD SOLUTION*/}

        {/*<ul className={cnHeaderMenuMobile('List')}>*/}
        {/*  {mainMenuItems.map((item, index) => {*/}
        {/*    const {*/}
        {/*      node: { title, nodeUrl, externalLink, targetBlank },*/}
        {/*      nodes,*/}
        {/*      columns,*/}
        {/*      sideColumn,*/}
        {/*      additionalNodes,*/}
        {/*    } = item || {};*/}
        {/*    const tagWrapperAttrs = setParams({*/}
        {/*      externalLink,*/}
        {/*      nodeUrl,*/}
        {/*      targetBlank,*/}
        {/*    });*/}

        {/*    return (*/}
        {/*      <li*/}
        {/*        className={cnHeaderMenuMobile('Item')}*/}
        {/*        key={cnHeaderMenuMobile('Item', { index })}*/}
        {/*      >*/}
        {/*        <div className={cnHeaderMenuMobile('ItemWrapper')}>*/}
        {/*          <Button*/}
        {/*            className={cnHeaderMenuMobile('Link', {})}*/}
        {/*            view="transparent"*/}
        {/*            as="a"*/}
        {/*            label={title}*/}
        {/*            size="s"*/}
        {/*            align="left"*/}
        {/*            {...tagWrapperAttrs}*/}
        {/*            onClick={() => servicesHandler(title, nodeUrl)}*/}
        {/*          />*/}

        {/*          {(nodes?.length > 0 || columns?.length > 0) && (*/}
        {/*            <Button*/}
        {/*              className={cnHeaderMenuMobile('CollapseIcon', {*/}
        {/*                active: getChecked(item),*/}
        {/*              })}*/}
        {/*              view="transparent"*/}
        {/*              size="s"*/}
        {/*              iconRight={IconArrowDown}*/}
        {/*              onClick={e => getOnChange(item)(e)}*/}
        {/*            />*/}
        {/*          )}*/}
        {/*        </div>*/}
        {/*        {nodes && (*/}
        {/*          <ul*/}
        {/*            className={cnHeaderMenuMobile('SubList', {*/}
        {/*              active: getChecked(item),*/}
        {/*            })}*/}
        {/*          >*/}
        {/*            {nodes.map(*/}
        {/*              (*/}
        {/*                {*/}
        {/*                  title,*/}
        {/*                  nodeUrl,*/}
        {/*                  externalLink,*/}
        {/*                  targetBlank,*/}
        {/*                  logo,*/}
        {/*                  label,*/}
        {/*                },*/}
        {/*                index,*/}
        {/*              ) => {*/}
        {/*                const tagWrapperAttrs = setParams({*/}
        {/*                  externalLink,*/}
        {/*                  targetBlank,*/}
        {/*                  nodeUrl,*/}
        {/*                });*/}
        {/*                return (*/}
        {/*                  <li*/}
        {/*                    className={cnHeaderMenuMobile('SubItem')}*/}
        {/*                    key={cnHeaderMenuMobile('SubItem', { index })}*/}
        {/*                  >*/}
        {/*                    <Button*/}
        {/*                      className={cnHeaderMenuMobile('Link', {})}*/}
        {/*                      view="text"*/}
        {/*                      align="left"*/}
        {/*                      size="s"*/}
        {/*                      label={title}*/}
        {/*                      {...tagWrapperAttrs}*/}
        {/*                      exact*/}
        {/*                    />*/}
        {/*                  </li>*/}
        {/*                );*/}
        {/*              },*/}
        {/*            )}*/}
        {/*          </ul>*/}
        {/*        )}*/}
        {/*        <ul*/}
        {/*          className={cnHeaderMenuMobile('SubList', {*/}
        {/*            active: getChecked(item),*/}
        {/*          })}*/}
        {/*        >*/}
        {/*          {columns &&*/}
        {/*            [...columns, sideColumn]?.map((item, index) => {*/}
        {/*              const { title, nodes } = item || {};*/}
        {/*              return (*/}
        {/*                <li*/}
        {/*                  className={cnHeaderMenuMobile('Item')}*/}
        {/*                  key={cnHeaderMenuMobile('Item', { index })}*/}
        {/*                >*/}
        {/*                  <div className={cnHeaderMenuMobile('ItemWrapper')}>*/}
        {/*                    <Button*/}
        {/*                      className={cnHeaderMenuMobile('Link', {})}*/}
        {/*                      view="transparent"*/}
        {/*                      // as="a"*/}
        {/*                      label={title}*/}
        {/*                      size="s"*/}
        {/*                      align="left"*/}
        {/*                    />*/}
        {/*                    <Button*/}
        {/*                      className={cnHeaderMenuMobile('CollapseIcon', {*/}
        {/*                        active: getChecked(item),*/}
        {/*                      })}*/}
        {/*                      view="transparent"*/}
        {/*                      size="s"*/}
        {/*                      iconRight={IconArrowDown}*/}
        {/*                      onClick={e => getOnChange(item)(e)}*/}
        {/*                    />*/}
        {/*                  </div>*/}
        {/*                  <ul*/}
        {/*                    className={cnHeaderMenuMobile('SubList', {*/}
        {/*                      active: getChecked(item),*/}
        {/*                    })}*/}
        {/*                  >*/}
        {/*                    {nodes.map(*/}
        {/*                      (*/}
        {/*                        {*/}
        {/*                          title,*/}
        {/*                          nodeUrl,*/}
        {/*                          externalLink,*/}
        {/*                          targetBlank,*/}
        {/*                          logo,*/}
        {/*                          label,*/}
        {/*                        },*/}
        {/*                        index,*/}
        {/*                      ) => {*/}
        {/*                        const tagWrapperAttrs = setParams({*/}
        {/*                          externalLink,*/}
        {/*                          targetBlank,*/}
        {/*                          nodeUrl,*/}
        {/*                        });*/}

        {/*                        return (*/}
        {/*                          <li*/}
        {/*                            className={cnHeaderMenuMobile('SubItem')}*/}
        {/*                            key={cnHeaderMenuMobile('SubItem', {*/}
        {/*                              index,*/}
        {/*                            })}*/}
        {/*                          >*/}
        {/*                            <Button*/}
        {/*                              className={cnHeaderMenuMobile('Link', {})}*/}
        {/*                              view="text"*/}
        {/*                              align="left"*/}
        {/*                              size="s"*/}
        {/*                              // label={title}*/}
        {/*                              {...tagWrapperAttrs}*/}
        {/*                              exact*/}
        {/*                              onClick={() =>*/}
        {/*                                servicesHandler(title, nodeUrl)*/}
        {/*                              }*/}
        {/*                            >*/}
        {/*                              {logo && (*/}
        {/*                                <img*/}
        {/*                                  src={logo.urlWebp || logo.url}*/}
        {/*                                  alt={title}*/}
        {/*                                  className="Picture"*/}
        {/*                                />*/}
        {/*                              )}*/}
        {/*                              {title}*/}
        {/*                              {logo && <IconOpenInNew />}*/}
        {/*                            </Button>*/}
        {/*                          </li>*/}
        {/*                        );*/}
        {/*                      },*/}
        {/*                    )}*/}
        {/*                  </ul>*/}
        {/*                </li>*/}
        {/*              );*/}
        {/*            })}*/}
        {/*          {additionalNodes &&*/}
        {/*            additionalNodes.map(*/}
        {/*              ({*/}
        {/*                 title,*/}
        {/*                 nodeUrl,*/}
        {/*                 externalLink,*/}
        {/*                 targetBlank,*/}
        {/*                 logo,*/}
        {/*                 label,*/}
        {/*               }) => {*/}
        {/*                const tagWrapperAttrs = setParams({*/}
        {/*                  externalLink,*/}
        {/*                  targetBlank,*/}
        {/*                  nodeUrl,*/}
        {/*                });*/}
        {/*                return (*/}
        {/*                  <li className={cnHeaderMenuMobile('SubItem')}>*/}
        {/*                    <Button*/}
        {/*                      className={cnHeaderMenuMobile('Link', {})}*/}
        {/*                      view="text"*/}
        {/*                      align="left"*/}
        {/*                      size="s"*/}
        {/*                      label={title}*/}
        {/*                      {...tagWrapperAttrs}*/}
        {/*                      exact*/}
        {/*                      onClick={() => servicesHandler(title, nodeUrl)}*/}
        {/*                    />*/}
        {/*                  </li>*/}
        {/*                );*/}
        {/*              },*/}
        {/*            )}*/}
        {/*        </ul>*/}
        {/*      </li>*/}
        {/*    );*/}
        {/*  })}*/}
        {/*</ul>*/}
        {contactUsItem && (
          <div className={cnHeaderMenuMobile('Cta')}>
            <Button
              // className={cnHeaderMenuMobile('Link', {})}
              size="m"
              view="secondary"
              iconRight={IconArrowRight}
              {...setParams({ ...contactUsItem.node })}
            />
          </div>
        )}
      </div>
      <div className={cnHeaderMenuMobile('Languages')}>
        {languages?.map(lang => {
          const isActive = language?.slug === lang.slug;
          let redirectPath: string;
          if (!lang.slug) {
            redirectPath = generatePath(`/${match.path.substring(18)}`, {
              ...match.params,
              lang: lang.slug,
            });
          } else {
            redirectPath = generatePath(match.path, {
              ...match.params,
              lang: lang.slug,
            });
          }
          return (
            <a
              href={redirectPath}
              className={cnHeaderMenuMobile('Languages-Item', {
                Active: isActive,
              })}
              key={lang.slug}
            >
              <Typography.Text
                size="xs"
                weight="bold"
                transform="uppercase"
                onClick={() => localStorage.setItem('currentLang', lang.slug)}
              >
                {lang.slug === '' ? 'EN' : lang.slug.toUpperCase()}
              </Typography.Text>
            </a>
          );
        })}
      </div>
    </nav>
  );
};
